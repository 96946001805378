@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f4f4f9;
  font-family: 'Helvetica', sans-serif;
}

h1 {
  font-size: 3rem;
  text-align: center;
}

h2 {
  font-size: 2rem;
  text-align: center;
}

h3 {
  font-size: 1.5rem;
  text-align: center;
}

p {
  font-size: 1.2rem;
}

.react-tabs__tab-list {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0;
  border-bottom: 1px solid #ccc;
}

.react-tabs__tab {
  margin-right: 10px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #333;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.react-tabs__tab--selected {
  background-color: white;
  border-color: #ccc #ccc white;
  border-bottom: 1px solid white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
